.action {
  font-weight: $font-weight-bold;
  font-size: $font-size-base-mobile;
  line-height: (24/16);
  border: 0;
  color: $primary;
  display: inline-block;
  transition: $transition-link;

  @include breakpoint(lg) {
    font-size: $font-size-base;
  }

  &::after {
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    margin-left: 5px;
    width: 13px;
    height: 13px;
    background-size: 13px 13px;
    background-position: 0 1px;
    background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($primary)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");
    transition: $transition-link;

  }

  &:hover,
  &:active,
  a:hover &,
  a:active & {
    color: $link-color;

    &::after {
      margin-left: 11px;
      background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 13 13' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.26666667 7.93333333h10.53191553l-4.86524887-4.86527898v-.80138768h.80138769l6.23333338 6.23333333-6.23333338 6.2333333h-.80138769v-.8013877l4.86524887-4.86527893h-10.53191553z' fill='#{hex_encode($link-color)}' transform='translate(-2 -2)'/%3E%3C/svg%3E");
    }
  }

  &.is-external::after {
    transform: rotate(-45deg);
  }

  &.is-download::after {
    transform: rotate(90deg);
  }
}
