//
// Page header
//

.page-header {
  @include container-outdent();
  @include container-padding();

  padding-top: 72px; // sticky header height

  @include breakpoint(lg) {
    padding-top: 107px;  // sticky header height
  }

  .page-header__inner {
    @include make-container();
  }

  .page-header__text {
    max-width: $text-max-width;
    padding-top: $line-space-2x;
    padding-bottom: $line-space-2x;

    @include breakpoint(md) {
      padding-top: $line-space-4x;
      padding-bottom: $line-space-2x;
    }
  }

  .is-eyebrow {
    display: block;
    margin: -26px 0 rem(8);
  }

  h1 {
    margin: 0;
    text-transform: none;
  }

  .is-lede {
    margin: 0;
  }


  // colors

  &.is-bkg--white,
  &.is-bkg--gray-light,

  &.is-bkg--blue-light {
    * {
      color: $text-dark;
    }
  }

  &.is-bkg--gray-dark,
  &.is-bkg--blue-dark,
  &.is-bkg--yellow,
  &.is-bkg--blue {
    * {
      color: $white;
    }


  }

  &.is-bkg--yellow {
    .is-lede {
      color: $text-muted;
    }
  }

}
