.block--intro {
  @include container-padding();
  margin-top: 0;


  .block__inner {
    @include make-container();
    padding-top: $line-space-2x;
    padding-bottom: $line-space-2x;
  }

  // space out columns
  @include breakpoint(xl) {
    .grid {
      justify-content: space-between;

      .grid__col {
        width: 45%;
        flex-basis: 45%;
      }
    }
  }

  // typography adjustments
  .is-lede {
    p, ul, ol {
      margin-bottom: 0.75em;
    }

    .btn {
      margin-top: 0.5em;
    }
  }
}
