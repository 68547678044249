//
// Site footer
//


.site-footer {
  @include container-padding();
  background-color: $white;
  background-repeat: no-repeat;
  border-top: 1px solid $gray-rule;


  @include breakpoint(lg) {
    background-image: url(../../img/footer-bg.png);
    background-position: calc(50% + 350px) 50%;
  }

  @include breakpoint(xl) {
    background-position: right center;
  }

  .footer__inner {
    @include make-container();
    padding-top: $line-space-2x;
    padding-bottom: $line-space-2x;

    @include breakpoint(lg) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  a {
    border: 0;
    text-decoration: none;
    color: $gray-dark;
    font-size: rem(18);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .footer__branding {
    width: 270px;
    margin: 0 auto;
    max-width: 100%;

    @include breakpoint(lg) {
      width: 322px;
      margin: 0;
    }
  }

  .footer__nav {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-left: auto;
    }
  }

  .footer__nav-main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 0 1 auto;
    padding: rem(16) 0 rem(24);

    li {
      width: 100%;
      text-align: center;
      margin: rem(4) 0;
    }

    a {
      color: $gray-dark;
      border: 0;

      &:hover, &:active {
        color: $primary;
      }
    }

    @include breakpoint(md) {
      padding: rem(16) 0;
      margin: 0 auto;

      li {
        width: auto;
        margin: 0 rem(14);
      }
    }

    @include breakpoint(lg) {
      li {
        text-align: left;
        margin: 0 0 0 rem(25);
      }
    }

  }

}
