
html {
  font-size: 1rem;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base-mobile;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  color: $text-color;
  background: $white;
  @include font-smoothing(on);

  @include breakpoint(lg) {
    font-size: $font-size-base;
  }
}

a {
  color: $link-color;
  text-decoration: underline;
  transition: $transition-link;

  &:hover, &:active, &:focus {
    color: $link-color-hover;
    text-decoration: none;
  }
}

strong {
  font-weight: $font-weight-bold;
}

p, ol, ul, dl {
  margin: 0 0 rem(24) 0;
}

blockquote, figure {
  margin: 0;
}

p, li {
  color: $gray-dark;
}

ul, ol {
  margin-left: 20px;
  padding: 0;
}

li {
  padding: 0;
  margin: 0;
  line-height: $line-height-base;
}

li + li,
li > ul {
  margin-top: 0.5em;
}

ul.is-plain {
  margin-left: 0;
  padding-left: 0;
  text-indent: none;
  list-style: none;
}

.is-lede, .is-lede p, %is-lede {
  font-size: rem(18);
  line-height: (24/18);
  color: $text-black;

  @include breakpoint(lg) {
    font-size: rem(19);
    line-height: (28/19);
  }
}

.is-lede {
  margin-bottom: rem(24);
}

.is-eyebrow {
  font-size: rem(13);
  line-height: (18/13);
  font-weight: $font-weight-bold;
  color: $text-muted;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include breakpoint(lg) {
    font-size: rem(16);
    line-height: (24/16);
  }
}

.is-small, .small, small {
  font-size: rem(13);
  line-height: rem(18);
}

hr {
  border: 0;
  border-bottom: 1px solid $gray-rule;
}

nav ul,
nav ol {
  @include list-reset();
}

img, svg {
  max-width: 100%;
  height: auto;
}

svg.icon path {
  fill: currentColor;
}

figure {
  img {
    display: block;
  }
}

figcaption, .figcaption {
  display: block;
  margin: rem(10) 0 0;
  font-size: rem(13);
  line-height: (18/13);
  font-style: normal;
}

blockquote {
  color: $primary;
  font-size: rem(21);
  line-height: (28/21);
  font-style: normal;
  font-weight: $font-weight-bold;
  max-width: 630px;
  text-transform: uppercase;

  p {
    color: inherit;
    margin-bottom: $line-space-half;
  }

  &.quotes {
    position: relative;

    &::before {
      content: '“';
      position: absolute;
      left: -0.45em;
      top: 0;
    }

    > p:last-of-type::after {
      content: '”';
    }
  }

  @include breakpoint(lg) {
    font-size: rem(27);
    line-height: (36/27);
    letter-spacing: -0.4px;
  }

  footer {
    font-weight: $font-weight-light;
    font-size: rem(16);
    line-height: (24/16);
    letter-spacing: 0;
  }
}

address {
  font-style: normal;

  p {
    margin-bottom: $line-space-half;
  }
}

// &#32;<br class="br-responsive">
br.br-responsive {
  @include breakpoint-down(sm) {
    display: none;
  }
}


//
// Headings
//

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .h4-h3 {
  display: block;
  margin: 0;
  padding: 0;
  color: $blue;
  text-transform: uppercase;
}

h1, .h1 {
  font-weight: $font-weight-bold;
  font-size: rem(32);
  line-height: (32/28);
  letter-spacing: 0;
  margin-bottom: 1em;

  @include breakpoint(lg) {
    font-size: rem(48);
    line-height: (48/40);
  }
}

h2, .h2 {
  font-weight: $font-weight-bold;
  font-size: rem(21);
  line-height: (28/21);

  @include breakpoint(lg) {
    font-size: rem(27);
    line-height: rem(36);
  }
}

h3, .h3 {
  font-weight: $font-weight-bold;
  font-size: rem(18);
  line-height: rem(24);
}

h4, .h4 {
  font-weight: $font-weight-bold;
  font-size: rem(18);
  line-height: rem(24);
  color: $text-muted;
}

h5, .h5 {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-black;
  letter-spacing: normal;
  text-transform: none;
}

h6, .h6 {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-muted;
  letter-spacing: normal;
  text-transform: none;
}

// spacing

h1.is-title {
  margin-bottom: 0;
}

h2, .h2 {
  & + * {
    margin-top: 0.75em;
  }

  * + & {
    margin-top: $line-space-2x;
  }
}

h3, .h3 {
  & + * {
    margin-top: 0.75em;
  }

  * + & {
    margin-top: $line-space-2x;
  }
}

h4, .h4, h5, .h5, h6, .h6 {
  & + * {
    margin-top: 0;
  }

  * + & {
    margin-top: 1em;
  }
}

.is-eyebrow + * {
  margin-top: 0;
}
