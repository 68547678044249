//
// Variables
//


//
// Colors
//

$blue-light: #e6f5fd;
$blue: #00a4ed;
$blue-text: #0070b3; // darker for links and small blue text accessibility
$blue-dark: #00375D;
$yellow: #FFB81C;

$primary: $blue;
$secondary: $yellow;

$white: #FFFFFF;
$black: #000000;

$gray-light: #d3d2d3;
$gray-medium: #707070;
$gray-dark: #4d4d4d;

$gray-rule: #C7C9C7;
$gray-border: #B0B0B0;

$text-color: $gray-dark;
$text-black: $black;
$text-dark: $gray-dark;
$text-muted: $gray-medium;
$text-error: #b60000;
$text-success: #3d6308;

$link-color: $blue-text;
$link-color-hover: $blue;


//
// Typography
//

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;

$font-family-base: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-size-base: 1.1875rem; // 19px
$font-size-base-mobile: 1.125rem; // 18px
$line-height-base: (24/16); // 1.5

$font-size-larger: 1.5rem; // 24px
$font-size-large: 1.25rem; // 20px
$font-size-small: 0.875rem; // 14px

$heading-size-1: 2.5rem; // 40px
$heading-size-2: 1.6875rem; // 36px
$heading-size-3: 1.125rem; // 18px
$heading-size-4: 1rem; // 16px
$heading-size-5: 1rem; // 16px
$heading-size-6: 1rem; // 16px

//
// Transitions
//

$transition-base: all 0.6s ease;
$transition-btn: all 0.3s ease;
$transition-link: all 0.3s ease;


//
// Grid breakpoints
//

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);


// Grid containers
//
// Outer padding

$container-padding-xs: 20px;
$container-padding-md: 40px;
$container-padding-lg: 40px;

$container-max-width: 1280px;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 19px;
$grid-gutter-width-md: 40px;
$grid-gutter-width-lg: 40px;

// Base line spacing
$baseline-grid-unit: .25rem; // 4px

$line-space: $baseline-grid-unit * 6; // 24px
$line-space-1-5x: $line-space * 1.5; // 36px
$line-space-2x: $line-space * 2; // 48px
$line-space-2-5x: $line-space * 2; // 60px
$line-space-3x: $line-space * 3; // 72px
$line-space-4x: $line-space * 4; // 96px
$line-space-7x: $line-space * 7; // 184px
$line-space-8x: $line-space * 8; // 184px

$line-space-half: $line-space * 0.5; // 12px;

$section-space-mobile: $line-space-2x;
$section-space: $line-space-4x;

// Content

$text-max-width: 840px;
$text-max-width-wide: 1100px;
$text-max-width-small: 620px;

//
// Z-indexes
//

$z-index-header: 30;
$z-index-logo: 31;
$z-index-nav: 20;
$z-index-nav-dropdown: 30;
$z-index-nav-overlay: 30;
$z-index-nav-overlay-bg: 10;
$z-index-dropdown: 9;
