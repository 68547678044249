.block--cta {
  @include container-padding();

  .block__inner {
    @include make-container();
    padding-top: $line-space-2x;
    padding-bottom: $line-space-2x;
  }

  .cta__body {
    max-width: $text-max-width;
    color: $text-dark;
    @extend %is-lede;

    .h2 {
      color: $white;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .cta__img {
    height: 100%;
    display: flex;
    align-items: center;

    @include breakpoint(md) {
      padding-left: percentage(1/12);
    }
  }

  // colors

  &.is-bkg--white,
  &.is-bkg--gray-light,

  &.is-bkg--blue-light {
    * {
      color: $text-dark;
    }
  }

  &.is-bkg--gray-dark,
  &.is-bkg--blue-dark,
  &.is-bkg--yellow,
  &.is-bkg--blue {
    * {
      color: $white;
    }


  }

  &.is-bkg--yellow {
    * {
      color: $text-dark;
    }

    .h2 {
      color: $white;
    }
  }
}
