//
// Backgrounds for page headers, heros, pullquotes etc.
//

//
// Colors

// Yellow

.is-bkg--yellow {
    background-color: $yellow !important;
    color: $text-dark;
}

// Blue

.is-bkg--blue {
    background-color: $blue !important;
    color: $white;
}


// Light blue

.is-bkg--blue-light {
    background-color: $blue-light !important;
    color: $text-dark;
}


// Light blue

// .is-bkg-blue-x-light {
//     background-color: $blue-x-light !important;
//     color: $text-dark;
// }

// Dark blue

.is-bkg--blue-dark {
    background-color: $blue-dark !important;
    color: $white;
}

// Black

.is-bkg--black {
    background-color: $black !important;
    color: $white;
}

// Dark gray

.is-bkg--gray-dark {
    background-color: $gray-dark !important;
    color: $text-dark;
}

// Medium gray

.is-bkg--gray-medium {
    background-color: $gray-medium !important;
    color: $white;
}

// Light gray

.is-bkg--gray-light {
    background-color: $gray-light !important;
    color: $text-dark;
}

// White

.is-bkg--white {
    background-color: $white !important;
    color: $text-dark;
}


//
// Patterns

// Squares

.is-bkg--squares {
    background-position: top left !important;
    background-size: 420px 420px !important;
    background-repeat: repeat !important;
    &.is-bkg--blue-dark {
        background-image: url(../../img/patterns/snc-pattern-squares-blue-40.png);
    }
    &.is-bkg--gray-dark {
        background-image: url(../../img/patterns/snc-pattern-squares-white-10.png);
    }
    &.is-bkg--blue,
    &.is-bkg--yellow {
        background-image: url(../../img/patterns/snc-pattern-squares-white-22.png);
    }
    &.is-bkg--gray-light,
    &.is-bkg--blue-x-light {
        background-image: url(../../img/patterns/snc-pattern-squares-white-100.png);
    }
}

// Crosses

.is-bkg--crosses {
    background-position: top left !important;
    background-size: 70px 70px !important;
    background-repeat: repeat !important;
    &.is-bkg--blue-dark {
        background-image: url(../../img/patterns/snc-pattern-crosses-blue-40.png);
    }
    &.is-bkg--gray-dark {
        background-image: url(../../img/patterns/snc-pattern-crosses-white-10.png);
    }
    &.is-bkg--blue,
    &.is-bkg--yellow {
        background-image: url(../../img/patterns/snc-pattern-crosses-white-30.png);
    }
    &.is-bkg--gray-light,
    &.is-bkg--blue-x-light {
        background-image: url(../../img/patterns/snc-pattern-crosses-white-100.png);
    }
}

// Rings

.is-bkg--rings {
    background-position: top left !important;
    background-size: 325px 325px !important;
    background-repeat: repeat !important;

    &.is-bkg--blue-dark {
        background-image: url(../../img/patterns/snc-pattern-rings-blue-40.png);
    }
    &.is-bkg--gray-dark {
        background-image: url(../../img/patterns/snc-pattern-rings-white-10.png);
    }
    &.is-bkg--blue,
    &.is-bkg--yellow {
        background-image: url(../../img/patterns/snc-pattern-rings-white-25.png);
    }
    &.is-bkg--gray-light,
    &.is-bkg--blue-x-light {
        background-image: url(../../img/patterns/snc-pattern-rings-white-100.png);
    }
}

// Dots

.is-bkg--dots {
    background-position: top left !important;
    background-size: 300px 300px !important;
    background-repeat: repeat !important;
    &.is-bkg--blue-dark {
        background-image: url(../../img/patterns/snc-pattern-dots-blue-25.png);
    }
    &.is-bkg--gray-dark {
        background-image: url(../../img/patterns/snc-pattern-dots-white-10.png);
    }
    &.is-bkg--blue,
    &.is-bkg--yellow {
        background-image: url(../../img/patterns/snc-pattern-dots-white-20.png);
    }
    &.is-bkg--gray-light,
    &.is-bkg--blue-x-light {
        background-image: url(../../img/patterns/snc-pattern-dots-white-100.png);
    }
}
