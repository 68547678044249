.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  fill: currentColor;
}

.icon-facebook,
.icon-linkedin,
.icon-twitter,
.icon-vimeo,
.icon-youtube {
  width: 30px;
  height: 30px;
}

.icon-arrow-left {
  transform: rotate(180deg);
}

.icon-arrow-down {
  transform: rotate(90deg);
}

.icon-arrow-external {
  transform: rotate(-45deg);
}

.icon-next {
  transform: rotate(180deg);
}
