//
// Text alignment helpers
//

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// rings

.bg-rings {
  background-position: top left !important;
  background-size: 325px 325px !important;
  background-repeat: repeat !important;
  background-image: url(../../img/patterns/snc-pattern-rings-white-25.png);
}


.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-white,
.text-light {
  color: $white;
}

.text-gray,
.text-muted {
  color: $text-muted;
}

.text-dark {
  color: $text-color;
}


//
// Force column break
//
.w100 {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}


//
// Screenreaders
//
.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}

//
// Toggling visibility of list items
//

.is-visually-hidden {
  @include sr-only();
  opacity: 0;
}

.is-visually-shown {
  transition: opacity 0.3s ease;
}

.is-hidden {
  display: none !important;
}

.is-invisible {
  visibility: hidden !important;
}

//
// Transitions
//

// .fade {
//   @include transition($transition-fade);

//   &:not(.show) {
//     opacity: 0;
//   }
// }

// .collapse {
//   &:not(.show) {
//     display: none;
//   }
// }

// .collapsing {
//   position: relative;
//   height: 0;
//   overflow: hidden;
//   @include transition($transition-collapse);
// }

