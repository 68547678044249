.block--tiles {

  .grid {
    margin-top: 0;
  }

  .grid__col {
    margin: 0;
    padding: 0;
  }

  .tile {
    //min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $line-space-2x;
    height: 100%;

    @include breakpoint(lg) {
      //min-height: 300px;
    }

    @include breakpoint(xl) {
      min-height: 450px;
    }
  }

  // Stats

  .tile--stat {
    .tile__inner {
      max-width: 400px;

      @include breakpoint(md) {
        max-width: 310px;
      }

    }

    .stat__heading {
      margin-bottom: 0;
      font-size: rem(48);

      @include breakpoint(lg) {
        font-size: rem(85);
      }
    }

    .stat__body {
      font-weight: $font-weight-bold;
      font-size: rem(21);

      @include breakpoint(lg) {
        font-size: rem(23);
      }
    }
  }

  // Patient Safety Starts Here

  .tile--safety {

    .tile__arrow {
      width: 80px;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
    }

    .tile__body {
      font-size: rem(30);
      font-weight: $font-weight-light;
      line-height: (45/38);
      color: $white;

      @include breakpoint(lg) {
        font-size: rem(38);
      }
    }
  }

  // Quote

  .tile--quote {
    .tile__inner {

      max-width: 400px;

      @include breakpoint(md) {
        max-width: 300px;
      }

    }

    blockquote {
      p, footer, &::before, p:last-child::after {
        color: $text-dark;
      }
    }
  }

  // Adjust stacking order

  @include breakpoint-only(md, xl) {
    .grid__col {
      order: 2;
    }

    .grid__col.w-1of2-md {
      order: 1;
    }
  }
}
