//
// Block
//

.block {
  @include make-container();
  margin-top: $line-space-2x;

  @include breakpoint(lg) {
    margin-top: $line-space-2x;
  }
}

.block--full {
  max-width: none;
  @include container-outdent();

  // .block + &,
  // .section + &,
  // & + .block--text {
  //   @include breakpoint(lg) {
  //     margin-top: $line-space-4x;
  //   }
  // }

  .block--full + & {
    margin-top: 0;
  }
}

.block .block:first-child {
  margin-top: 0;
}

.block.is-block-flush {
  margin-top: 0;

  & + .block {
    margin-top: 0 !important;
  }
}


//
// Block types
//

@import "blocks/block-agenda";
@import "blocks/block-cta";
@import "blocks/block-divider";
@import "blocks/block-figure";
@import "blocks/block-intro";
@import "blocks/block-pager";
@import "blocks/block-text";
@import "blocks/block-tiles";
