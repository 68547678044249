//
// Pagination buttons
//

.pager {
  display: flex;
  font-size: rem(15);
  font-weight: $font-weight-bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;

  li {
    flex: 0 1 auto;
    min-width: rem(48);

    &.pager__prev,
    &.pager__next {
      flex: 1 0 auto;
    }

    a, span {
      display: block;
      padding: 12px;
      border: 1px solid $gray-border;
      color: $gray-dark;
    }

    span {
      cursor: default;
    }

    &.is-active a,
    a:hover, a:focus {
      border-color: $primary;
      position: relative;
      z-index: 2;
      color: $primary;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }

    &.is-disabled a,
    &.is-disabled a:hover,
    &.is-disabled a:focus {
      color: $gray-rule;
      border-color: $gray-rule;
      cursor: default;
      pointer-events: none;
      z-index: 0;
    }

  }

  // Only show current page and next/prev on mobile
  @include breakpoint-down(sm) {
    li:not(.is-active):not(.pager__prev):not(.pager__next) {
      display: none;
    }
  }

}
