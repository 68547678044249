$stripe-color: #f0f0f0;

.block--agenda.block--text {
  .block__inner {
    max-width: 100%;
  }
}

.block--heading.block--text + .block--agenda.block--text {
  margin-top: rem(24);
}

.block--agenda + .block--divider,
.block--agenda + .block--full {
  @include breakpoint(lg) {
    // tighten collapsible spacing
    margin-top: calc(#{$line-space-2x} - 11px);
  }
}

// section header

.agenda--heading {
  background: $blue;
  color: $white;
  padding: rem(20);
  font-size: rem(15);
  margin-top: $line-space;

  .agenda__title {
    font-size: rem(23);
    line-height: (25/23);
  }

  .is-eyebrow {
    margin-top: 0;
  }
}

// item row

.agenda--row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.is-striped {
    background: $stripe-color;
  }

  .agenda__item {
    flex: 0 1 auto;
    padding: rem(15);
    font-size: rem(15);

    &:first-child {
      width: 10%;
      text-align: right;
    }

    &:not(:first-child) {
      flex: 1;
    }
  }

  // stacked mobile view

  @include breakpoint-down(md) {
    flex-direction: column;
    padding: rem(15);

    .agenda__item {
      padding: 0;
    }

    .agenda__item:first-child {
      text-align: left;
    }
  }
}

// Collapse toggle

.block--agenda {
  .block__inner:not(.collapse) {
    // undo tightened collapsible spacing if not collapsible
    padding-bottom: 11px;
  }

  .block__inner.collapse {
    max-height: 80vh;
    overflow: hidden;
    transition: max-height 0.3s ease;
    position: relative;

    @include breakpoint(md) {
      max-height: 350px;
    }
  }

  .btn-collapse {
    @include button-reset();
    width: 100%;
    position: relative;
    padding: 15px;

    &:hover {
      cursor: pointer;
    }

    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
  }

  .block__inner.collapse[aria-expanded="false"] + .btn-collapse::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    height: 65px;
    width: 100%;
    background: linear-gradient(rgba($white, 0), $white);
  }

  .block__inner.collapse + .btn-collapse::after {
    // icon down
    content: '';
    height: 10px;
    width: 18px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 9px);
    background-image: url("data:image/svg+xml,%3Csvg height='10' viewBox='0 0 18 10' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m237 20h1l8 8 8-8h1v1l-9 9-9-9z' fill='%23b0b0b0' fill-rule='evenodd' transform='translate(-237 -20)'/%3E%3C/svg%3E");

  }

  .block__inner.collapse[aria-expanded="true"] + .btn-collapse::after {
    // icon down
    transform: rotate(180deg);

  }
}
