.block--slider {

  .slider__slide {
    width: 100%;

    @include breakpoint(sm) {
      width: 50%;
    }

    @media (min-width: 1420px) {
      width: 710px;
    }
  }

  .flickity-button-icon {
    fill: $white;
  }

  .flickity-prev-next-button {
    background: rgba($black, 0.4);
  }
}
