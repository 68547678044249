body.is-hero-page {
  padding-top: 0 !important;
}

.page-hero {
  position: relative;
  overflow: hidden;
  @include container-padding();
  @include container-outdent();

  .page-hero__inner {
    min-height: 350px;
    @include make-container();
    display: flex;
    flex-direction: column;

    .page-hero__content {
      width: 100%;
      height: 100%;
      flex: 1;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      position: relative;

      @include breakpoint-down(sm) {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
      }

      .btn {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 2;

        @include breakpoint(md) {
          bottom: 30px;
          right: 20px;
        }

        @include breakpoint(xl) {
          right: 40px;
          bottom: 60px;
        }
      }
    }

    @include breakpoint(sm) {
      min-height: 350px;

      .page-hero__content {
        width: 60%;
      }
    }

    @include breakpoint(md) {
      min-height: 455px;
    }

    @include breakpoint(lg) {
      min-height: 580px;

      .page-hero__content {
        width: 50%;
        background-position: bottom left;
      }
    }

    @include breakpoint(xl) {
      min-height: 600px;

      .page-hero__content {
        margin-left: -40px;
        margin-right: -40px;
      }
    }
  }

  .page-hero__img {
    position: absolute;
    top: 0;
    left: 60%;
    width: 50vw;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: none;

    @include breakpoint(sm) {
      display: block;
    }

    @include breakpoint(lg) {
      left: 50%;
      width: 50vw;
    }
  }
}
