.btn {
  display: inline-block;
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  font-size: rem(18);
  color: $white;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: $secondary;
  border: 0;
  border-radius: 0;
  padding: 10px 14px;
  line-height: 1;
  transition: $transition-btn;
  text-decoration: none;
  max-width: 100%;
  text-transform: none;

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
    cursor: pointer;
    background-color: darken($secondary, 7);
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  @include breakpoint(md) {
    font-size: rem(20);
  }

  @include breakpoint(lg) {
    font-size: rem(26);
    padding: 11px 23px;
  }

  &.btn-reverse {
    color: $white !important;
    border: 1px solid white;

    &:hover,
    &:focus {
      color: $white;
      border-color: $white;
      opacity: 0.65;
    }
  }

  .is-bkg--yellow & {
    color: $white !important;
    background: $gray-dark;

    &:hover,
    &:focus {
      color: $white;
      opacity: 0.65;
    }
  }

  &.btn-small,
  &.btn-label {
    font-size: rem(13);
    line-height: (18/13);
    letter-spacing: 0.5px;
    padding: 4px 8px 5px;

    .icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin: -6px -3px -5px 2px;
    }
  }

  &.btn-label {
    text-transform: none;
    letter-spacing: 0;
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
    background-color: $text-muted;
    border-color: $text-muted;
    color: $white;
    transition: all 0s;
  }
}
