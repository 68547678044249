//
// Figures
//

.block--figure {
  .block__inner {
    max-width: $text-max-width;
  }
}

.block--figure.is-figure-small {

  figure {
    width: calc(75% + #{$grid-gutter-width});
    max-width: 400px;
    margin: 0 auto;
  }

  @include breakpoint(lg) {
    // float right on large screens
    float: right;
    width: 330px;

    figure {
      width: auto;
      margin: 0 0 $grid-gutter-width-lg $grid-gutter-width-lg;

    }

    // remove top margin if this is the first block in a section
    &:first-child + .block--text {
      margin-top: 0 !important;
    }
  }
}
