//
// Forms
//

$input-border-color: $gray-border;
$input-border-focus-color: $gray-dark;
$input-border-radius: 0;
$input-placeholder-color: $text-muted;
$input-color: $black;
$label-color: $gray-dark;
$error-color: $text-error;
$success-color: $text-success;

form, fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

//
// All inputs
//
.form-control {
  font-family: $font-family-base;
  font-size: rem(16);
  font-weight: $font-weight-normal;
  color: $input-color;
  line-height: normal;
  padding: 8px;
  border: 1px solid $input-border-color;
  display: block;
  width: 100%;
  //height: 60px;
  background: $white;
  border-radius: $input-border-radius;

  @include breakpoint(lg) {
    padding: 12px 10px;
  }

  &::placeholder {
    color: $input-placeholder-color;
    font-weight: $font-weight-normal;
  }

  &:focus,
  &.focus {
    outline: 0;
    border-color: $input-border-focus-color;
  }

  // Hack ugly autocomplete webkit styles
  &:-internal-autofill-selected,
  &:-webkit-autofill {
    -webkit-text-fill-color: $black;
    background-color: $white !important;
    box-shadow: 0 0 0 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.form-control-lg {
  @include breakpoint(lg) {
    font-size: rem(19);
    padding: 16px 17px;
  }
}

//
// Textareas
//
textarea.form-control {
  height: auto;
  // use rows="8" to adjust the height in your markup
}

//
// Labels
//
label,
.label {
  font-family: $font-family-base;
  font-size: rem(16);
  line-height: (24/16);
  font-weight: $font-weight-bold;
  color: $label-color;
  display: block;
  margin: 0 0 rem(5);

  a {
    font-weight: $font-weight-normal;
  }
}

//
// Select dropdown
//

select.form-control {
  appearance: none;
  border-color: $input-border-color;
  background-image: url("data:image/svg+xml,%3Csvg height='10' viewBox='0 0 18 10' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m237 20h1l8 8 8-8h1v1l-9 9-9-9z' fill='%23b0b0b0' fill-rule='evenodd' transform='translate(-237 -20)'/%3E%3C/svg%3E");background-image: url("data:image/svg+xml,%3Csvg height='10' viewBox='0 0 18 10' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m237 20h1l8 8 8-8h1v1l-9 9-9-9z' fill='#{hex-encode($gray-border)}' fill-rule='evenodd' transform='translate(-237 -20)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 18px 10px;
  background-position: calc(100% - 16px) 50%;
  padding-right: 40px;
  vertical-align: middle;

  &.placeholder {
    color: $input-placeholder-color;
  }

  &::-ms-expand {
    display: none;
  }
}

//
// Checkboxes and Radios
//

.form-check,
.form-radio {
  position: relative;
  display: block;
  padding-left: 26px;

  .form-check + & {
    margin-top: rem(30);
  }

  &.form-check-inline {
    display: inline-block;
  }

  .form-check-inline + &.form-check-inline {
    margin: 0 0 0 rem(30);
  }

  .form-check-input {
    opacity: 0;
    position: absolute;
  }

  .form-check-label {
    display: inline-block;
    font-family: $font-family-base;
    font-size: rem(16);
    font-weight: $font-weight-normal;
    line-height: normal;
    color: $input-color;
    position: relative;
  }

  .form-check-label::before,
  .form-check-label::after {
    content: "";
    position: absolute;
    top: 1px;
    left: -26px;
    width: 18px;
    height: 18px;
    border-radius: $input-border-radius;
    background-repeat: no-repeat;
    margin: 0 !important;

  }

  .form-check-label::before {
    border: 1px solid $input-border-color;
    pointer-events: none;
    background: $white;
  }


  // checkboxes

  .form-check-input:checked ~ label::before {
    border-color: $input-border-color;
    background-color: $input-border-color;
  }

  .form-check-input:checked ~ label::after {
    background-image: url("data:image/svg+xml,%3Csvg height='11' viewBox='0 0 15 11' width='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m254.5 654.5 4 4m0 0 7.5-7.5' fill='none' stroke='#{hex_encode($white)}' stroke-linecap='square' stroke-width='2' transform='translate(-253 -649)'/%3E%3C/svg%3E");
    background-size: 15px 11px;
    background-position: 2px 3px;
    background-color: $input-border-color;
  }

  // radios

  .form-check-input[type="radio"] ~ label::before,
  .form-check-input[type="radio"] ~ label::after {
    border-radius: 9rem;
    background-image: none;
  }

  .form-check-input[type="radio"]:checked ~ label::before {
    border-color: $input-border-color;
  }

  .form-check-input[type="radio"]:checked ~ label::after {
    width: 16px;
    height: 16px;
    background: $input-border-color;
    border: 2px solid $white;
    top: 2px;
    left: -25px;
  }
}

//
// Search box
//

.input-group {
  position: relative;

  &--search {
    button {
      @include button-reset();
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      color: $gray-medium;
      cursor: pointer;

      .icon {
        width: 24px;
        height: 24px;
        position: relative;
        top: 2px;
      }
    }
  }
}

//
// Error states
//

.form-control.is-invalid,
.form-check-input.is-invalid ~ .form-check-label::before {
  border-color: $error-color;
}

.form-check-input.is-invalid ~ .form-check-label {
  color: $error-color;
}

.invalid-feedback {
  @include list-reset();
  margin-top: 4px;

  &,
  li {
    color: $error-color;
  }
}

//
// User feedback
//

.alert,
.alert-success,
.alert-danger {
  font-weight: $font-weight-bold;
  margin: $line-space-2x 0;
  border: 1px solid $gray-rule;
  padding: $line-space;
}

.alert-danger {
  border-color: $text-error;
  color: $text-error;
}

.alert-success {
  border-color: $text-success;
  color: $text-success;
}

//
// Row spacing
//

.form-group,
.form-group:last-child {
  margin: 0 0 $line-space;
}
