//
// Site header
//

.site-header {
  @include container-padding();
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background: rgba($white, 0.9);
  z-index: $z-index-header;

  .header__inner {
    @include make-container();
    padding-top: rem(12);

    @include breakpoint(lg) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @include breakpoint(md) {
    height: 90px;
  }

  @include breakpoint(lg) {
    height: 107px;
  }
}

//
// Logo
//

.header__branding {
  width: 138px;
  position: relative;
  z-index: $z-index-logo;

  a {
    border: 0;
    text-decoration: none;
  }

  @include breakpoint(md) {
    width: 205px;
  }

  @include breakpoint(lg) {
    width: 270px;
  }
}


//
// Main nav
//

.header__nav {
  @include breakpoint(lg) {
    margin-left: auto;
  }
}

.header__nav-main {

  // Base styles

  a {
    border: 0;
    text-decoration: none;
    color: $black;
    font-size: rem(18);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  .icon-search {
    position: relative;
    top: 5px;
  }


  // Mobile styles

  @include breakpoint-down(lg) {

    li {
      margin-bottom: 1px;
      text-align: center;
    }

    a,
    a:hover,
    a:active,
    li:hover a {
      display: block;
      padding: rem(6) 0;
      color: $black;
      font-size: rem(21);
      line-height: (28/21);
    }

    a:hover,
    a:active,
    li:hover a {
      color: $primary;
    }
  }

  // Desktop styles

  @include breakpoint(lg) {
    margin-left: rem(5);
    display: flex;
    align-items: center;

    a {
      color: $black;
    }

    a:hover,
    a:focus,
    a:active,
    > li > a.is-active,
    > li:hover > a {
      color: $primary;
    }

    > li {
      position: relative;
    }

    > li > a {
      display: inline-block;
      padding: rem(12);
    }
  }
}


//
// Mobile nav overlay
//

.header__nav {

  .close {
    display: none;
    cursor: pointer;
  }

  @include breakpoint-down(lg) {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 300px;
    z-index: $z-index-nav;
    background: $white;
    padding: rem(120) 0 rem(40);
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity .3s linear, transform .3s ease;
    overflow: hidden;
    @include make-container();

    .header__nav-inner {
      @include container-padding();
    }

    .header__nav-inner > ul {
      position: relative;
    }

    a {
      opacity: 0;
      transition: opacity .3s linear, transform .3s ease;
    }

    ul {
      transition: opacity .3s linear, transform .3s ease;
    }

    .is-nav-open & {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);

      ul > li > a {
        opacity: 1;
      }

      .close {
        display: block;
        cursor: pointer;
        text-transform: uppercase;
      }
    }

    .close {
      @include button-reset();
      color: $gray-dark;
      font-weight: $font-weight-bold;
      position: absolute;
      top: 20px;
      right: 20px;

      .icon {
        margin-left: 3px;
        transform: scale(0.8);
      }

      span {
        position: relative;
        top: -7px;
      }

      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }

      @include breakpoint(md) {
        top: 34px;
      }
    }
  }
}


//
// Mobile nav toggle
//

.header__nav-toggle {
  @include button-reset();
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: center;
  font-size: rem(16);
  line-height: (24/16);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: $gray-dark;
  transition: $transition-link;

  .icon {
    margin-left: 4px;
  }

  span {
    position: relative;
    top: 0;
  }

  &:hover,
  &:focus,
  &:active {
    color: $primary;
  }

  @include breakpoint(md) {
    top: 34px;
  }

  @include breakpoint(lg) {
    display: none;
  }
}

.nav-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: $z-index-nav-overlay-bg;
  backdrop-filter: blur(5px);

  &.fade-in {
    animation-delay: 0.3s;
    animation-duration: 0.3s;
  }
}
