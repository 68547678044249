.block--text {
  *:last-child {
    margin-bottom: 0;
  }

  .block__inner {
    max-width: $text-max-width;
  }
}

.block--text-wide {
  *:last-child {
    margin-bottom: 0;
  }

  .block__inner {
    max-width: $text-max-width-wide;
  }
}

.block--text + .block--text {
  margin-top: $line-space-2x;
}

.block--heading + .block--text {
  margin-top: 0.75em;
}

.hero--page + .block--text {
  margin-top: $line-space-2x;

  @include breakpoint(lg) {
    margin-top: $line-space-4x;
  }
}
